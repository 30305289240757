import { useEffect, useMemo, useState } from "react";
import { NIL, v4 as uuidv4 } from "uuid";
import { useLocation, useParams } from "react-router-dom";
import { convertFromHTML } from "draft-js";

import {
  CreateRole,
  DeleteAutoAdvanceCondition,
  PostAutoAdvanceCondition,
  PostRoleStatus,
  PutUpdateRole,
  UpdateRoleStatus,
  getAllAssessments,
  getRankType,
} from "../../../WebCalls";
import Notification from "../../Shared/Notification";
import useApiToken from "../../../hooks/useApiToken";
import CreateRoleStatus from "./CreateRoleStatus";
import CreateProcessAutomation from "./CreateProcessAutomation";
import {
  areArraysEqual,
  areObjectsEqual,
  RoleStatusType,
} from "../../../Helpers";
import { AutoAdvanceCondition, Role, RoleStatus } from "../../../Models";
import TextEditor from "../../TextEditor/TextEditor";
import { TextEditorFields } from "../../Shared/TextEditorFields";
import { InputField } from "../../Shared/InputFields";
import { clearLocalStorageExceptDark } from "../../TextEditor/textEditorConfig";
import AllAssessmentsDropDown from "./AllAssessmentsDropDown";
import RankingType from "./RankingType";

export type recruitmentProcess = {
  id: string;
  title: string;
  email: string;
};
export type AssessmentCondition = {
  id: string;
  title: string;
};

const defaultRankType = {
  id: NIL,
  name: "Overall",
};

const getProcessAndAutoAdvanceCondition = (
  roleStatuses: RoleStatus[] | undefined
) => {
  const orderedRoleStatus = roleStatuses?.sort((a, b) => a.order - b.order);
  const autoAdvanceConditions: AutoAdvanceCondition[] = [];

  const processesForRole: recruitmentProcess[] = [];
  orderedRoleStatus?.forEach((rs) => {
    rs.autoAdvanceConditions?.forEach((aac) => {
      autoAdvanceConditions.push(aac);
    });
    processesForRole.push({ id: rs.id, title: rs.name, email: rs.email });
  });
  return { autoAdvanceConditions, processesForRole };
};

export default function CreateRoleForm({
  role,
  roleStatuses,
}: {
  role: Role | undefined;
  roleStatuses: RoleStatus[] | undefined;
}) {
  const [title, setTitle] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [offerEmail, setOfferEmail] = useState("");
  const [rejectionEmail, setRejectionEmail] = useState("");
  const [assessmentLink, setAssessmentLink] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [processes, setProcesses] = useState<recruitmentProcess[]>([
    { id: uuidv4(), title: RoleStatusType.rejected, email: rejectionEmail },
    { id: uuidv4(), title: RoleStatusType.hired, email: offerEmail },
  ]);
  const [selectedRankType, setSelectedRankType] = useState(defaultRankType);
  const [rankTypes, setRankTypes] = useState<{ id: string; name: string }[]>(
    []
  );
  const [processAutomations, setProcessAutomations] = useState<
    AutoAdvanceCondition[]
  >([]);

  const [textEditorType, setTextEditorType] = useState("");
  const [showTextEditor, setShowTextEditor] = useState(false);
  const [recruitmentProcessId, setRecruitmentProcessId] = useState("");
  const roleId = useParams();

  const [allAssessments, setallAssessments] = useState<AssessmentCondition[]>(
    []
  );
  const [assessments, setAssessments] = useState<AssessmentCondition[]>([]);
  const [selectedAssessment, setSelectedAssessment] =
    useState<AssessmentCondition | null>(null);
  const newRoleId = uuidv4();
  const token = useApiToken();

  const url = useLocation();

  const isEditRole = url.pathname.includes("edit");

  const formFields = [
    {
      id: 1,
      label: "Job title",
      name: "jobTitle",
      inputId: "jobTitle",
      placeholder: "e.g. Junior Software Engineer",
      autoComplete: "jobTitle",
      value: title,
      setValue: setTitle,
    },
    {
      id: 2,
      label: "Employment type",
      name: "employmentType",
      inputId: "employmentType",
      placeholder: "e.g. Full time, Part time, Internship",
      autoComplete: "employmentType",
      value: employmentType,
      setValue: setEmploymentType,
    },
    {
      id: 3,
      label: "Location",
      name: "location",
      inputId: "location",
      placeholder: "e.g. Remote",
      autoComplete: "location",
      value: location,
      setValue: setLocation,
    },
    {
      id: 4,
      label: "Salary",
      name: "salary",
      inputId: "salary",
      placeholder: "e.g. US$950-US$1,300 per month",
      autoComplete: "salary",
      value: salary,
      setValue: setSalary,
    },
  ];

  const textEditorFields = [
    {
      id: 1,
      label: "Job description",
      state: jobDescription,
      setTextEditorType,
      setShowTextEditor,
    },
    {
      id: 3,
      label: "Offer email",
      state: offerEmail,
      setTextEditorType,
      setShowTextEditor,
    },
    {
      id: 4,
      label: "Rejection email",
      state: rejectionEmail,
      setTextEditorType,
      setShowTextEditor,
    },
  ];

  const { processesForRole, autoAdvanceConditions } = useMemo(() => {
    return getProcessAndAutoAdvanceCondition(roleStatuses);
  }, [roleStatuses]);

  useEffect(() => {
    if (!token) return;
    getRankType(token).then((data) => {
      data.unshift(defaultRankType);
      setRankTypes(data);
    });
  }, [token]);
  //Filling the form with role data
  useEffect(() => {
    if (!role) return;
    setTitle(role.title);
    setEmploymentType(role.employmentType);
    setLocation(role.location);
    setSalary(role.salary);
    setJobDescription(role.description);

    const assessment = allAssessments.find(
      (a) => a.id === role.assessmentQuestionnaireId
    );
    if (assessment) {
      setSelectedAssessment(assessment);
      setAssessments([assessment]);
    }

    const ranking = rankTypes.find((rt) => rt.id === role.rankTypeId);
    if (ranking) setSelectedRankType(ranking);

    if (!roleStatuses) return;

    roleStatuses.forEach((rs) => {
      if (rs.name === RoleStatusType.hired) setOfferEmail(rs.email ?? "");
      if (rs.name === RoleStatusType.rejected)
        setRejectionEmail(rs.email ?? "");
    });

    //this code is for creating the new ids and mapping them correctly
    if (!isEditRole) {
      const processesForRoleWithNewId = processesForRole.map((pr) => {
        const newId = uuidv4();
        autoAdvanceConditions.forEach((aac) => {
          if (aac.autoAdvanceStatusId === pr.id)
            aac.autoAdvanceStatusId = newId;
          if (aac.startStatusId === pr.id) aac.startStatusId = newId;
        });
        return { ...pr, id: newId };
      });
      autoAdvanceConditions.forEach((aac) => {
        aac.id = uuidv4();
      });
      setProcesses(processesForRoleWithNewId);
    } else {
      setProcesses(processesForRole);
    }
    setProcessAutomations(autoAdvanceConditions);
  }, [
    role,
    roleStatuses,
    rankTypes,
    allAssessments,
    isEditRole,
    processesForRole,
    autoAdvanceConditions,
  ]);
  const isFormValid = () => {
    return (
      title.trim() !== "" &&
      employmentType.trim() !== "" &&
      location.trim() !== "" &&
      salary.trim() !== "" &&
      convertFromHTML(jobDescription).contentBlocks.length !== 0 &&
      convertFromHTML(offerEmail).contentBlocks.length !== 0 &&
      convertFromHTML(rejectionEmail).contentBlocks.length !== 0
    );
  };

  const handleSaveClick = (e: React.FormEvent) => {
    e.preventDefault();
    if (!token) return;
    const formData: Role = {
      id: newRoleId,
      title: title.trim(),
      employmentType: employmentType.trim(),
      location: location.trim(),
      salary: salary.trim(),
      description: jobDescription.trim(),
      isActive: false,
      rankTypeId: selectedRankType.id,
      assessmentQuestionnaireId: selectedAssessment?.id,
      date: new Date().toISOString(),
    };

    CreateRole(formData, token).then((res) => {
      setTitle("");
      setEmploymentType("");
      setLocation("");
      setSalary("");
      setJobDescription("");
      setOfferEmail("");
      setSelectedRankType(defaultRankType);
      setRejectionEmail("");

      const roleStatuses: RoleStatus[] = processes.map((process, index) => {
        var email = null;
        if (process.title === RoleStatusType.hired) {
          email = offerEmail;
        } else if (process.title === RoleStatusType.rejected) {
          email = rejectionEmail;
        } else {
          email = process.email;
        }

        return {
          id: process.id,
          name: process.title,
          email: email,
          roleId: newRoleId,
          order: index + 1,
        };
      });

      roleStatuses[roleStatuses.length - 1] = {
        ...roleStatuses[roleStatuses.length - 1],
        order: roleStatuses.length - 1,
      };

      Promise.all(
        roleStatuses.map(async (roleStatus) => {
          await PostRoleStatus(roleStatus, token);
        })
      );
      Promise.all(
        processAutomations.map(async (pa) => {
          await PostAutoAdvanceCondition(pa, token);
        })
      );
      setShowNotification(true);
      setProcessAutomations([]);
      setProcesses([
        { id: uuidv4(), title: RoleStatusType.rejected, email: rejectionEmail },
        { id: uuidv4(), title: RoleStatusType.hired, email: offerEmail },
      ]);
      clearLocalStorageExceptDark();
      setTitle("");
      setEmploymentType("");
      setLocation("");
      setSalary("");
      setAssessmentLink("");
      setJobDescription("");
      setOfferEmail("");
      setRejectionEmail("");
    });
  };

  const handleUpdateClick = async (e: React.FormEvent) => {
    if (!token || !role) return;
    e.preventDefault();

    const formData: Role = {
      id: role.id,
      title: title.trim(),
      employmentType: employmentType.trim(),
      location: location.trim(),
      salary: salary.trim(),
      description: jobDescription.trim(),
      isActive: role.isActive,
      rankTypeId: selectedRankType.id,
      assessmentQuestionnaireId: selectedAssessment?.id,
      date: role.date,
    };

    await PutUpdateRole(role.id, formData, token);
    const statusUpdatePromises = processes.map(async (p) => {
      const prev = processesForRole.find((pr) => pr.id === p.id);
      if (prev && !areObjectsEqual(prev, p)) {
        const update = roleStatuses?.find((rs) => rs.id === p.id);
        if (update) {
          return UpdateRoleStatus(p.id, token, {
            id: p.id,
            email: p.email,
            roleId: update.roleId,
            order: update.order,
            name: p.title,
          });
        }
      }
    });
    await Promise.all(statusUpdatePromises);

    const processAutomationsToDelete = autoAdvanceConditions.filter(
      (aac) => !processAutomations.some((pa) => pa.id === aac.id)
    );
    const processAutomationsToAdd = processAutomations.filter(
      (pa) => !autoAdvanceConditions.some((aac) => aac.id === pa.id)
    );
    await Promise.all(
      processAutomationsToDelete.map(async (pa) => {
        await DeleteAutoAdvanceCondition(pa.id, token);
      })
    );
    await Promise.all(
      processAutomationsToAdd.map(async (pa) => {
        await PostAutoAdvanceCondition(pa, token);
      })
    );

    setShowNotification(true);
    setTimeout(() => {
      window.location.reload();
    }, 500);

    clearLocalStorageExceptDark();
  };

  const isFormChanged = () => {
    if (!role) return;
    const automationIsSame = areArraysEqual(
      processAutomations,
      autoAdvanceConditions
    );

    const processIsSame = areArraysEqual(processesForRole, processes);

    const formIsChanged =
      title.trim() !== role.title ||
      employmentType.trim() !== role.employmentType ||
      location.trim() !== role.location ||
      salary.trim() !== role.salary ||
      jobDescription.trim() !== role.description ||
      selectedRankType.id !== role.rankTypeId ||
      selectedAssessment?.id !== role.assessmentQuestionnaireId ||
      !automationIsSame ||
      !processIsSame;

    return formIsChanged;
  };

  const handleTextEditor = (type: string) => {
    setTextEditorType(type);
    setShowTextEditor(true);
    window.scrollTo(0, 0);
  };

  const getDefaultStateValue = (textEditorType: string) => {
    const selectedProcess = processes.find(
      (process) => process.id === recruitmentProcessId
    );
    switch (textEditorType) {
      case "Job description":
        return jobDescription;
      case "Offer email":
        return offerEmail;
      case "Rejection email":
        return rejectionEmail;
      case recruitmentProcessId:
        return selectedProcess?.email;
      default:
        return "";
    }
  };
  useEffect(() => {
    getAllAssessments().then((res) => setallAssessments(res));
  }, []);

  return (
    <>
      {!showTextEditor ? (
        <form>
          <div className="space-y-12 sm:space-y-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
            <div>
              {!isEditRole && (
                <p className="mt-6 max-w-2xl text-sm leading-6 text-gray-600 dark:text-gray-300">
                  This jobs you create are not active by default.
                </p>
              )}
              <div>
                <div className="mt-4 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 dark:border-gray-800  dark:sm:divide-gray-800">
                  {formFields.map((field) => (
                    <InputField
                      key={field.id}
                      label={field.label}
                      name={field.name}
                      inputId={field.inputId}
                      placeholder={field.placeholder}
                      autoComplete={field.autoComplete}
                      value={field.value}
                      setValue={field.setValue}
                    />
                  ))}

                  <AllAssessmentsDropDown
                    allAssessments={allAssessments}
                    setAssessments={setAssessments}
                    selectedAssessment={selectedAssessment}
                    setSelectedAssessment={setSelectedAssessment}
                  />

                  <CreateRoleStatus
                    processes={processes}
                    setProcesses={setProcesses}
                    handleTextEditor={handleTextEditor}
                    setRecruitmentProcessId={setRecruitmentProcessId}
                  />
                  {textEditorFields.map((field) => (
                    <div
                      key={field.id}
                      className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
                    >
                      <TextEditorFields
                        label={field.label}
                        state={field.state}
                        setTextEditorType={field.setTextEditorType}
                        setShowTextEditor={field.setShowTextEditor}
                      />
                    </div>
                  ))}
                  <RankingType
                    selected={selectedRankType}
                    setSelected={setSelectedRankType}
                    rankTypes={rankTypes}
                  />
                  <CreateProcessAutomation
                    processes={processes}
                    processAutomations={processAutomations}
                    setProcessAutomations={setProcessAutomations}
                    assessments={assessments}
                    allAssessments={allAssessments}
                  />
                </div>
              </div>
              {isEditRole ? (
                <div className="mt-6 pb-8 flex items-center justify-end">
                  <button
                    type="button"
                    className={`inline-flex justify-center rounded-md bg-entntblue px-5 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntblue ${
                      !(isFormValid() && isFormChanged())
                        ? "disabled:bg-gray-300 cursor-not-allowed"
                        : "hover:bg-entntorange"
                    }`}
                    onClick={handleUpdateClick}
                    disabled={!(isFormValid() && isFormChanged())}
                  >
                    Update
                  </button>
                </div>
              ) : (
                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="button"
                    className={`inline-flex justify-center rounded-md bg-entntblue px-5 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntblue ${
                      !isFormValid()
                        ? "disabled:bg-gray-300 cursor-not-allowed"
                        : "hover:bg-entntorange"
                    }`}
                    onClick={handleSaveClick}
                    disabled={!isFormValid()}
                  >
                    Create
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      ) : (
        <TextEditor
          textEditorType={textEditorType}
          setShowTextEditor={setShowTextEditor}
          setJobDescription={setJobDescription}
          setOfferEmail={setOfferEmail}
          setRejectionEmail={setRejectionEmail}
          setProcesses={setProcesses}
          recruitmentProcessId={recruitmentProcessId}
          defaultStateValue={
            roleId?.roleId && getDefaultStateValue(textEditorType)
          }
        />
      )}
      <Notification
        show={showNotification}
        setShow={setShowNotification}
        title={`${isEditRole ? "Updated" : "Created"} Successfully`}
        description={`Role has been ${isEditRole ? "updated" : "created"} successfully`}
        type="success"
      />
    </>
  );
}
