import { useEffect, useState } from "react";
import { CandidateStatus, RoleStatus } from "../../../Models";
import { CandidateAction } from "./RoleCandidates";
import { RadioGroup } from "@headlessui/react";
import { GradeCandidate } from "../../../WebCalls";
import useApiToken from "../../../hooks/useApiToken";
import Notes from "./Notes";
import { PencilSquareIcon } from "@heroicons/react/20/solid";

const getGrade = (grade: number | undefined) => {
  if (!grade) return "-";
  return grade >= 80 && grade < 90 ? "P" : grade >= 90 ? "E" : "F";
};

const getGradeColor = (grade: string) => {
  if (grade === "P") return "text-white bg-blue-600";
  if (grade === "E") return "text-white bg-green-600";
  if (grade === "F") return "text-white bg-red-600";
};
export default function Grade({
  roleStatuses,
  candidate,
  handleUpdateCandidates,
}: {
  roleStatuses: RoleStatus[];
  candidate: CandidateAction;
  handleUpdateCandidates: (
    candidatesUpdate: CandidateStatus[],
    gradeStatusUpdate?: boolean
  ) => void;
}) {
  const [grades, setGrades] = useState<string[]>([]);
  const [currentStatus, setCurrentStatus] = useState<RoleStatus>();
  const [noteIsOpen, setNoteIsOpen] = useState(false);
  const [gradeIsOpen, setGradeIsOpen] = useState(false);
  const token = useApiToken();

  useEffect(() => {
    const candidateGrades = roleStatuses
      .slice(1, -2)
      .map((rs) =>
        getGrade(
          candidate.candidateStatuses.find((c) => c.roleStatusId === rs.id)
            ?.grade
        )
      );
    setGrades(candidateGrades);
  }, [roleStatuses, candidate]);

  useEffect(() => {
    const handleScroll = () => {
      if (gradeIsOpen) {
        setGradeIsOpen(false);
        setNoteIsOpen(false);
      }
    };

    if (gradeIsOpen) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [gradeIsOpen, setNoteIsOpen]);

  const handleGradeChange = (
    index: number,
    newGrade: string,
    rs: RoleStatus
  ) => {
    const candidateStatus = candidate.candidateStatuses.find(
      (c) => c.roleStatusId === rs.id
    );
    if (!token || !candidateStatus) return;
    const updateCandidateStatus: CandidateStatus = {
      candidateId: candidateStatus.candidateId,
      roleId: candidateStatus.roleId,
      roleStatusId: candidateStatus.roleStatusId,
      date: new Date(candidateStatus.date).toISOString(),
      email: candidateStatus.email,
      grade: newGrade === "E" ? 100 : newGrade === "P" ? 89 : 79,
    };
    GradeCandidate(updateCandidateStatus, token).then((d) => {
      const newGrades = [...grades];
      newGrades[index] = newGrade;
      setGrades(newGrades);
      handleUpdateCandidates([updateCandidateStatus], true);
    });
  };

  const candidateReachedStatus = (rs: RoleStatus) => {
    return candidate.candidateStatuses.some((c) => c.roleStatusId === rs.id);
  };
  return (
    <div
      className="relative cursor-default"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="">
        <div className="relative">
          <button
            className="flex w-full items-center justify-between text-sm text-gray-400 hover:text-gray-500"
            onClick={() => {
              setGradeIsOpen(true);
            }}
          >
            <div className="flex space-x-1">
              {roleStatuses.slice(1, -2).map((rs, index) => (
                <span
                  key={rs.id}
                  title={rs.name}
                  className={`text-xs font-medium rounded-full px-1 ${getGradeColor(
                    grades[index]
                  )}`}
                >
                  {grades[index] || "-"}
                </span>
              ))}
            </div>
          </button>
          {gradeIsOpen && (
            <div
              className={
                "py-3 px-4 absolute left-10 bg-white rounded-md z-[11] border border-gray-200 shadow-lg h-fit dark:bg-darkbglight dark:border-gray-500"
              }
            >
              <div className="flex justify-between w-full">
                <div className={`space-y-3`}>
                  {grades &&
                    roleStatuses.slice(1, -2).map((rs, index) => (
                      <RadioGroup
                        key={index}
                        value={grades[index]}
                        onChange={(newGrade) =>
                          handleGradeChange(index, newGrade, rs)
                        }
                        className={`flex relative ${
                          !candidateReachedStatus(rs) ? "hidden" : "block"
                        }`}
                      >
                        <RadioGroup.Label className="flex items-center font-medium text-gray-700 min-w-[9rem] dark:text-gray-300">
                          <PencilSquareIcon
                            height={16}
                            className="pr-1 font-normal cursor-pointer"
                            onClick={() => {
                              setCurrentStatus(rs);
                              if (rs.id === currentStatus?.id)
                                setNoteIsOpen(!noteIsOpen);
                              else setNoteIsOpen(true);
                            }}
                          />
                          {rs.name} :{" "}
                        </RadioGroup.Label>
                        <div className="flex space-x-1">
                          <RadioGroup.Option value="E">
                            {({ checked }) => (
                              <span
                                className={`${
                                  checked ? "bg-green-600 text-white" : ""
                                }  rounded-full py-1 px-2 text-xs font-medium cursor-pointer`}
                              >
                                Excellent
                              </span>
                            )}
                          </RadioGroup.Option>
                          <RadioGroup.Option value="P">
                            {({ checked }) => (
                              <span
                                className={`${
                                  checked ? "bg-blue-600 text-white" : ""
                                } rounded-full py-1 px-2 text-xs font-medium cursor-pointer`}
                              >
                                Pass
                              </span>
                            )}
                          </RadioGroup.Option>
                          <RadioGroup.Option value="F">
                            {({ checked }) => (
                              <span
                                className={`${
                                  checked ? "bg-red-600 text-white" : ""
                                } rounded-full py-1 px-2 text-xs font-medium cursor-pointer`}
                              >
                                Fail
                              </span>
                            )}
                          </RadioGroup.Option>
                        </div>
                        {noteIsOpen && currentStatus?.id === rs.id && (
                          <Notes
                            candidateId={candidate.id}
                            roleStatus={currentStatus}
                          />
                        )}
                      </RadioGroup>
                    ))}
                </div>
                <div></div>
              </div>
            </div>
          )}
          {gradeIsOpen && (
            <div
              className="fixed inset-0 z-10 h-full"
              onClick={() => {
                setGradeIsOpen(false);
                setNoteIsOpen(false);
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}
