import { useEffect, useState } from "react";
import { CandidateComment, RoleStatus } from "../../../Models";
import useApiToken from "../../../hooks/useApiToken";
import { useParams } from "react-router-dom";
import useGraphApiToken from "../../../hooks/useGraphApiToken";
import useUserData from "../../../hooks/useUserData";
import {
  CreateCandidateComment,
  DeleteComment,
  GetCandidateComments,
  GetProfilePhoto,
  GetUserName,
} from "../../../WebCalls";
import { dateConverter, getAvatarName } from "../../../Helpers";
import { Spinner } from "../../Shared/Spinner";
import Avatar from "../../Shared/Avatar";
import { v4 as uuidv4 } from "uuid";

import {
  CheckIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

type comment = {
  personId: string;
  id: string;
  name: string;
  date: string;
  imageSrc: string | undefined;
  body: string;
};
export default function Notes({
  candidateId,
  roleStatus,
}: {
  candidateId: string;
  roleStatus: RoleStatus;
}) {
  const [note, setNote] = useState<string>("");
  const [candidateComments, setCandidateComments] = useState<
    CandidateComment[]
  >([]);
  const [deleteCommentConfirm, setDeleteCommentConfirm] = useState("");
  const [commentProfile, setCommentProfile] = useState<string>();
  const [comments, setComments] = useState<comment[]>([]);
  const token = useApiToken();
  const { roleId } = useParams();
  const [loadingComments, setLoadingComments] = useState(true);
  const graphToken = useGraphApiToken();
  const { username, userId } = useUserData();

  useEffect(() => {
    if (!candidateId || !token) return;
    GetCandidateComments(candidateId, token).then((cc) => {
      let filteredComments = cc;
      if (roleStatus)
        filteredComments = cc.filter((c) => c.roleStatusId === roleStatus.id);
      setCandidateComments(filteredComments);
    });
    if (!graphToken) return;
    GetProfilePhoto(graphToken).then((pr) => {
      if (pr) setCommentProfile(URL.createObjectURL(pr));
    });
  }, [candidateId, token, graphToken, roleStatus]);

  useEffect(() => {
    setLoadingComments(true);
    const setCommentWithProfile = async () => {
      //sorting comments based on date
      candidateComments.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
      const commentMappedWithImage = await Promise.all(
        candidateComments.map(async (el) => {
          let name: any;
          let profile: Blob | undefined;
          if (graphToken) {
            name = await GetUserName(el.personId, graphToken);
            profile = await GetProfilePhoto(graphToken, el.personId);
          }
          return {
            id: el.id,
            name: name.displayName,
            date: dateConverter(el.date, true),
            personId: el.personId,
            imageSrc: profile && URL.createObjectURL(profile),
            body: el.comment,
          };
        })
      );

      setComments(commentMappedWithImage);
      setLoadingComments(false);
    };
    setCommentWithProfile();
  }, [candidateComments, graphToken]);

  const handleCreateComment = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const personId = userId;
    if (
      candidateId !== undefined &&
      roleId !== undefined &&
      note !== undefined &&
      personId !== undefined &&
      token !== undefined
    ) {
      const body: CandidateComment = {
        id: uuidv4(),
        candidateId: candidateId,
        roleId: roleId,
        comment: note,
        roleStatusId: roleStatus?.id,
        personId: personId,
        date: new Date().toISOString(),
      };
      CreateCandidateComment(body, token).then((ncc) => {
        const newComment: comment = {
          id: ncc.id,
          name: username ?? "",
          date: dateConverter(ncc.date, true),
          imageSrc: commentProfile,
          body: ncc.comment,
          personId: ncc.personId,
        };
        setComments((prev) => [...prev, newComment]);
        setNote("");
      });
    }
  };
  const handleDeleteComment = (id: string) => {
    if (!token) return;
    DeleteComment(id, token).then(() => {
      const newCC = candidateComments.filter((cc) => cc.id !== id);
      setCandidateComments(newCC);
    });
  };

  return (
    <>
      <section
        aria-labelledby="notes-title"
        className="min-w-[25rem] absolute right-full top-2 border border-gray-200 rounded-xl shadow-xl p-1 bg-white dark:bg-darkbglight dark:border-gray-500"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="px-2 py-2">
          {loadingComments ? (
            <Spinner />
          ) : (
            <>
              {comments.length === 0 ? (
                <div className="text-gray-500 dark:text-gray-300 text-center my-2">
                  No notes to display.
                </div>
              ) : (
                <ul className="space-y-3 relative">
                  {comments.map((comment) => (
                    <li key={comment.id}>
                      <div className="flex space-x-2">
                        <div className="flex-shrink-0 flex-2 mt-1">
                          <Avatar
                            height="h-8"
                            width="w-8"
                            src={comment.imageSrc}
                            avatarName={getAvatarName(comment.name)}
                          />
                        </div>
                        <div className="flex-1 bg-white dark:bg-darkbglight border border-gray-200 px-3 py-1 rounded-lg dark:border-gray-500">
                          <div className="text-sm">
                            <span className="flex justify-between">
                              <span className="font-medium text-gray-900 dark:text-gray-300">
                                {comment.name}
                                <span className="font-normal text-xs text-gray-600 ml-2 dark:text-gray-400">
                                  {comment.personId}
                                </span>
                              </span>
                              {comment.personId === userId &&
                                (deleteCommentConfirm === comment.id ? (
                                  <div className="flex dark:text-gray-300">
                                    <span className="flex ml-4 space-x-2">
                                      <XMarkIcon
                                        height={20}
                                        className="text-red-600 cursor-pointer"
                                        onClick={() => {
                                          setDeleteCommentConfirm("");
                                        }}
                                      />
                                      <CheckIcon
                                        height={20}
                                        className="text-entntblue cursor-pointer"
                                        onClick={() => {
                                          handleDeleteComment(comment.id);
                                        }}
                                      />
                                    </span>
                                  </div>
                                ) : (
                                  <button
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setDeleteCommentConfirm(comment.id);
                                    }}
                                  >
                                    <TrashIcon
                                      height={16}
                                      className="text-red-600"
                                    />
                                  </button>
                                ))}
                            </span>
                          </div>
                          <div className="mt-1 text-sm text-gray-700 dark:text-gray-300">
                            <p>{comment.body}</p>
                          </div>
                          <div className="mt-2 space-x-2 text-xs">
                            <span className="font-normal text-gray-500 dark:text-gray-400">
                              {comment.date}
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
        <div className="bg-gray-50 px-2 py-2 rounded-b-xl dark:bg-darkbglight">
          <div className="flex space-x-2">
            <div className="flex-shrink-0 mt-1">
              <Avatar
                src={commentProfile}
                height="h-8"
                width="w-8"
                avatarName={username && getAvatarName(username)}
              />
            </div>
            <div className="min-w-0 flex-1">
              <form>
                <div className="relative">
                  <textarea
                    id="comment"
                    name="comment"
                    rows={2}
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-entntblue sm:text-sm sm:leading-6 dark:bg-transparent dark:text-gray-300"
                    placeholder="Add a note"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="absolute bottom-2 right-2 cursor-pointer rounded-full disabled:cursor-not-allowed"
                    onClick={handleCreateComment}
                    disabled={note.trim() === ""}
                  >
                    <PlusCircleIcon
                      className={`h-7  ${
                        note.trim() === ""
                          ? "text-gray-200"
                          : "text-entntblue hover:text-entntorange"
                      }`}
                    />
                  </button>
                </div>
                <div className="mt-3 flex items-center justify-between"></div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
