import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CandidateAction } from "./RoleCandidates";
import { Candidate } from "../../../Models";

type Headers = {
  name: string;
  asc: boolean;
  fieldName: string;
  sort: boolean;
  isActive: boolean;
};

const initialHeading: Headers[] = [
  {
    name: "Name",
    fieldName: "name",
    asc: true,
    sort: true,
    isActive: false,
  },
  {
    name: "Location",
    fieldName: "workLocation1",
    asc: true,
    sort: false,
    isActive: false,
  },
  { name: "Score", fieldName: "score", asc: true, sort: true, isActive: false },
  { name: "Grades", fieldName: "", asc: true, sort: false, isActive: false },
  {
    name: "Assignment",
    fieldName: "",
    asc: true,
    sort: false,
    isActive: false,
  },
  {
    name: "YOE/Grad Date",
    fieldName: "yearsOfExperience",
    asc: true,
    sort: true,
    isActive: false,
  },
  {
    name: "Last University/Company",
    fieldName: "",
    asc: true,
    sort: false,
    isActive: false,
  },
  // {
  //   name: "Time/Period",
  //   fieldName: "",
  //   asc: true,
  //   sort: false,
  //   isActive: false,
  // },
  {
    name: "Notice",
    fieldName: "noticePeriod",
    asc: true,
    sort: true,
    isActive: false,
  },

  // {
  //   name: "Date",
  //   fieldName: "latestStatusDate",
  //   asc: true,
  //   sort: true,
  //   isActive: false,
  // },
];
type props = {
  selectedCandidate: CandidateAction[];
  candidates: Candidate[];
  actionButtonsDisabled: boolean[];
  handleSelectedCandidates: (candidates: CandidateAction[]) => void;
  handleSort: (query: string) => void;
  handleBulkActionModal: (open: boolean) => void;
  handleBulkActionIsDisqualify: (open: boolean) => void;
  handleMediaAnswerDownload: () => void;
  handleAddAudioQuestion: () => void
};

export default function RoleCandidatesHeader({
  selectedCandidate,
  candidates,
  actionButtonsDisabled,
  handleSelectedCandidates,
  handleSort,
  handleBulkActionModal,
  handleBulkActionIsDisqualify,
  handleMediaAnswerDownload,
  handleAddAudioQuestion
}: props) {
  const [sort, setSort] = useState<Headers[]>([]);
  const [sortOptions, setSortOptions] = useState(initialHeading);
  const [checked, setChecked] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const checkbox = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedCandidate.length > 0 &&
      selectedCandidate.length !== candidates.length;
    setChecked(selectedCandidate.length === candidates.length);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedCandidate, candidates]);

  useEffect(() => {
    let sortQueryList: string[] = [];
    sort.forEach((s) => {
      if (s.fieldName === "name") {
        sortQueryList.push(`firstName${s.asc ? "" : " desc"}`);
        sortQueryList.push(`lastName${s.asc ? "" : " desc"}`);
      } else sortQueryList.push(`${s.fieldName}${s.asc ? "" : " desc"}`);
    });
    handleSort(sortQueryList.join(","));
  }, [sort, handleSort]);

  const handleSortChange = (sortOption: Headers) => {
    let updatedSort = [...sort];
    const updatedSortedoption = sortOptions.map((so) => {
      if (so.isActive && so.name === sortOption.name) {
        if (so.asc) {
          let changedSort = updatedSort.find((us) => us.name === so.name);
          if (changedSort) changedSort.asc = false;
          return { ...so, asc: false };
        } else {
          const ind = updatedSort.findIndex((us) => us.name === so.name);
          if (ind !== -1) {
            updatedSort.splice(ind, 1);
          }
          return { ...so, asc: true, isActive: false };
        }
      } else if (!so.isActive && so.name === sortOption.name) {
        updatedSort = [...updatedSort, { ...so, isActive: true }];
        return { ...so, isActive: true };
      } else {
        return so;
      }
    });

    setSortOptions(updatedSortedoption);
    setSort(updatedSort);
  };

  function toggleAll() {
    handleSelectedCandidates(
      checked || indeterminate ? [] : candidates
    );
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }
  return (
    <thead className="sticky top-16 bg-white dark:bg-darkbglight z-20 before:border-b before:border-gray-300 before:absolute before:content-[''] before:w-full before:h-full before:-z-10 dark:before:border-gray-500 dark:before:border-t">
      <tr className="">
        <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
          <input
            type="checkbox"
            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 dark:border-gray-100"
            ref={checkbox}
            checked={checked}
            onChange={toggleAll}
          />
        </th>
        {sortOptions.map((s, i) => (
          <th
            key={s.name}
            scope="col"
            className={`py-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 w-fit min-w-14`}
          >
            {selectedCandidate.length > 0 && (
              <div className="absolute top-0 flex h-10 items-center space-x-3 sm:left-12 z-30 bg-white dark:bg-darkbglight">
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-white"
                  onClick={() => {
                    handleBulkActionIsDisqualify(false);
                    handleBulkActionModal(true);
                  }}
                  disabled={
                    !selectedCandidate ||
                    selectedCandidate.length === 0 ||
                    actionButtonsDisabled[0]
                  }
                >
                  Advance
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-white"
                  onClick={() => {
                    handleBulkActionIsDisqualify(true);
                    handleBulkActionModal(true);
                  }}
                  disabled={
                    !selectedCandidate ||
                    selectedCandidate.length === 0 ||
                    actionButtonsDisabled[1]
                  }
                >
                  Disqualify
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-white"
                  onClick={handleMediaAnswerDownload}
                  disabled={
                    candidates.length === 0 ||
                    !selectedCandidate ||
                    selectedCandidate.length === 0||
                    actionButtonsDisabled[2]  
                  }
                >
                  Download Video
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-white"
                  onClick={handleAddAudioQuestion}
                  disabled={
                    candidates.length === 0 ||
                    !selectedCandidate ||
                    selectedCandidate.length === 0
                  }
                >
                  Add Audio Questions
                </button>
              </div>
            )}
            <div className="flex items-center">
              <span>{s.name}</span>
              {s.sort && (
                <span
                  className={`cursor-pointer hover:bg-gray-200 rounded-full dark:hover:bg-entntorange py-0.5 px-1.5 flex items-center ${s.isActive && "bg-blue-100 dark:bg-entntblue"
                    }`}
                  onClick={() => {
                    handleSortChange(s);
                  }}
                >
                  <span
                    className={
                      s.isActive ? "text-entntblue dark:text-gray-300" : ""
                    }
                  >
                    {s.asc ? (
                      <ArrowUpIcon height={15} />
                    ) : (
                      <ArrowDownIcon height={15} />
                    )}
                  </span>
                  <span
                    className={`${s.isActive && "text-entntblue dark:text-gray-300"
                      } text-xs font-medium`}
                  >
                    {s.isActive &&
                      sort.findIndex((st) => st.name === s.name) + 1}
                  </span>
                </span>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
}
