import React, { useState } from 'react';
import { Questions } from '../Models';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import { convert } from 'html-to-text';
import { deleteQuestion } from '../WebCalls';
import ConfirmModal from './Shared/ConfirmModal';
import Notification from './Shared/Notification';
import parse from 'html-react-parser'


const QuestionCard = ({ question }: { question: Questions }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showNotifcation, setShowNotification] = useState(false);
  const { id, text, type, tags, time, accuracy } = question;


  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/questions/edit/${id}`)

  }

  const handleDelete = () => {
    setIsModalOpen(true);
  }

  const confirmDelete = async () => {
    setIsModalOpen(false);
    await deleteQuestion(question);
    setShowNotification(true);
    setTimeout(() => {
      window.location.reload();
    }, 2000)

  };

  return (
    <>
      <tr className="bg-white border-b dark:bg-gray-900 border-2	border-gray-100 dark:border-gray-700">
        <td className="px-6 py-4 font-medium text-gray-900  dark:text-white">
          <div className='text-left dark:text-gray-300'>
            {text.length > 200 ? <>

              {expanded ?
                <div className='flex align-center justify-center flex-col items-center '>
                  <div>
                    {parse(text)}
                  </div>
                  <div>
                    <ChevronUpIcon height={17} color='gray' onClick={() => { setExpanded(false) }} className='cursor-pointer mt-2 dark:hover:text-white' />
                  </div>
                </div> :
                <div className='flex align-center justify-center flex-col items-center'>
                  <div>
                    {`${convert(text).substring(0, 200)}...`}
                  </div>
                  <div>
                    <ChevronDownIcon color='gray' height={17} className='cursor-pointer mt-2 dark:hover:text-white' onClick={() => setExpanded(true)} />
                  </div>
                </div>}

            </> : <>{parse(text)}</>}
          </div>
        </td>
        <td className="px-6 py-4 hidden sm:table-cell">{type[0].toUpperCase() + type.substring(1)}</td>
        <td className="px-6 py-4 hidden sm:table-cell">{time}</td>
        <td className="px-6 py-4 hidden sm:table-cell">{accuracy === -1 ? <small>Not Applicable</small> : accuracy + "﹪"}</td>
        <td className="px-6 py-4 hidden sm:table-cell">{tags}</td>
        <td className="px-6 py-4">
          <div className='flex flex-row'>
            <PencilSquareIcon className='font-medium text-gray-400 cursor-pointer dark:text-blue-500 hover:underline ' onClick={handleEdit} height={20} />
            <span className="mx-2">|</span>
            <TrashIcon className="font-medium text-red-600 cursor-pointer dark:text-red-500 hover:underline" onClick={handleDelete} height={20} />
          </div>
        </td>
      </tr >
      <ConfirmModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onConfirm={confirmDelete}
        type="danger"
        title="Delete Question"
        description=" Are you sure you want to delete this Question? All of the
        data will be permanently removed. This action cannot
        be undone."
      />
      <Notification
        show={showNotifcation}
        setShow={setShowNotification}
        title="Success"
        description="The question and all its answers were successfully deleted"
        type="success"
      />
    </>
  );
};

export default QuestionCard;
