import { createPortal } from "react-dom";
import React, { useEffect, useState, Fragment } from "react";
import { Transition } from "@headlessui/react";
import { postNewRankType } from "../../../../WebCalls";
import useApiToken from "../../../../hooks/useApiToken";

interface Props {
  isOpen: boolean;
  onCancel: React.Dispatch<React.SetStateAction<boolean>>;
  fetchAndSetRankType: () => Promise<void>;
  title: string;
  rankTypes: { id: string; name: string }[];
}

interface Error {
  err: boolean;
  message: string;
}

const CreateNewTypeModal = (props: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState<Error>({
    err: false,
    message: "",
  });
  const token = useApiToken();
  useEffect(() => {
    if (props.isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [props.isOpen]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (
        props.rankTypes.find(
          (role) => role.name.toLowerCase() === inputValue.toLowerCase()
        )
      ) {
        setError({
          err: true,
          message: "Rank type with same name already exists",
        });
      } else {
        if (error.err) {
          setError({
            err: false,
            message: "",
          });
        }
      }
    }, 250);

    return () => {
      clearTimeout(timerId);
    };
  }, [inputValue, props.rankTypes]);

  const onSubmitRoleType = async () => {
    if (!token) return;
    const data = await postNewRankType(inputValue, token);
    props.fetchAndSetRankType();
    if (data?.error) {
      setError({
        err: true,
        message: error.message,
      });
    }
    setInputValue("");
    props.onCancel(false);
  };

  return createPortal(
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          className={`${
            props.isOpen ? "transition-all" : "hidden"
          } fixed top-0 right-0 left-0 bottom-0  w-[100vw] z-20`}
        >
          <div
            className="absolute bg-gray-400 opacity-40 top-0 w-full h-screen z-10"
            onClick={() => props.onCancel(false)}
          ></div>
          <div
            className={`absolute top-0 right-0 bottom-0 left-0 max-w-[500px] max-h-max  m-auto z-50  p-4 `}
          >
            <header className="bg-gray-100 dark:bg-gray-700 rounded-t-xl p-2 text-center text-gray-700 dark:text-gray-400  font-medium ">
              {props.title}
            </header>
            <section className="flex flex-col bg-white dark:bg-darkbglight p-4 overflow-auto gap-4 py-12">
              <div className="flex items-center relative justify-center">
                <label
                  htmlFor="type"
                  className="text-sm font-semibold mr-4 text-gray-700 dark:text-gray-300"
                >
                  Rank type :{" "}
                </label>
                <input
                  type="text"
                  name="type"
                  placeholder="eg. Software, Shipping"
                  className={`placeholder:text-xs px-2 py-1 w-[60%] border-gray-300 rounded-xl text-sm dark:bg-gray-700 dark:text-white ${
                    error.err ? "border-red-500" : ""
                  }`}
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
              {error.err && (
                <span className="text-[0.65rem] text-red-500 absolute left-[12rem] bottom-[6rem]">
                  *{error.message}
                </span>
              )}
            </section>
            <footer className="bg-gray-100 dark:bg-gray-700 rounded-b-xl p-2 text-center text-gray-700 dark:text-gray-400  font-medium">
              <div className="flex justify-end gap-3">
                <button
                  className={`inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                    error.err
                      ? "bg-gray-400 hover:bg-none"
                      : "hover:bg-indigo-700 bg-indigo-600"
                  } `}
                  onClick={onSubmitRoleType}
                  disabled={error.err}
                >
                  Submit
                </button>
                <button
                  className="inline-flex items-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => {
                    props.onCancel(false);
                    setInputValue("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </footer>
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>,
    document.body
  );
};

export default CreateNewTypeModal;
