import { getStyleForCompany, getStyleForUniversity } from "../../Helpers";
import {
  TimelineCandidateEducation,
  TimelineCandidateExperience,
  TimelineGap,
} from "../../Models";
import { useEffect, useMemo, useRef, useState } from "react";

type TimelineItem =
  | TimelineCandidateEducation
  | TimelineCandidateExperience
  | TimelineGap;

interface TimelineType {
  timeline: TimelineItem[];
}

export default function CareerTimeline({ timeline }: TimelineType) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [timelineContainer, setTimelineContainer] =
    useState<HTMLDivElement | null>(null);

  const getBlockWidth = (months: number) => {
    if (!containerRef.current) return 0;
    return containerRef.current.clientWidth * ((months / 100) * 1.5) + 50;
  };

  const timelineArr: TimelineItem[][] = useMemo(() => {
    if (!timelineContainer) return [];

    let totalBlockWidth = 0;
    const tempTimelineArr: TimelineItem[][] = [];
    let tempArr: TimelineItem[] = [];
    timeline.forEach((ele: TimelineItem, i: number) => {
      const blockWidth: number = getBlockWidth(ele.totalMonths);
      totalBlockWidth += blockWidth;
      const shouldSegment =
        timelineContainer?.clientWidth - totalBlockWidth < 50;
      tempArr.push({ ...ele, blockWidth });
      if (shouldSegment || i === timeline.length - 1) {
        tempTimelineArr.push(tempArr);
        tempArr = [];
        totalBlockWidth = 0;
      }
    });
    return tempTimelineArr;
  }, [timelineContainer, timeline]);

  useEffect(() => {
    if (!containerRef.current) return;
    setTimelineContainer(containerRef.current);
  }, []);

  return (
    <div
      ref={containerRef}
      className="w-full sm:px-4 -mt-20 sm:-mt-10 sm:pb-5 h-full cursor-default"
    >
      {timelineArr.map((timeline: TimelineItem[], i: number) => (
        <div
          key={`timeline-block-row-${i}`}
          className="flex items-start gap-3"
          style={{
            flexDirection: i % 2 === 0 ? "row" : "row-reverse",
            marginTop: "48px",
          }}
        >
          {i > 0 && <div className="w-5 h-5 min-w-5" />}
          {timeline.map((ele: any, idx: number) => {
            return (
              <div
                key={`timeline-block-${idx}`}
                style={{
                  width: `${ele.blockWidth}px`,
                }}
              >
                <h4
                  className={`whitespace-pre-wrap h-8 overflow-hidden font-medium text-xs`}
                  title={ele.title ? ele?.company?.name : ele?.university?.name}
                >
                  {ele.title ? ele?.company?.name : ele?.university?.name}
                </h4>
                <h4
                  className={`w-10 h-10 text-white font-semibold rounded-full flex justify-center items-center mx-auto mb-2 ${
                    ele.gap ? "bg-gray-500" : undefined
                  }`}
                  style={{
                    background: !ele.gap
                      ? ele.title
                        ? getStyleForCompany(ele.company.ranking).background
                        : getStyleForUniversity(
                            ele.university.ranking,
                            ele.postGrad
                          ).background
                      : undefined,
                    color: !ele.gap
                      ? ele.title
                        ? getStyleForCompany(ele.company.ranking).color
                        : getStyleForUniversity(
                            ele.university.ranking,
                            ele.postGrad
                          ).color
                      : undefined,
                  }}
                >
                  {ele.totalMonths}
                </h4>
                <div
                  className={`h-3 rounded-full ${
                    ele.gap ? "bg-gray-500" : undefined
                  }`}
                  style={{
                    background: !ele.gap
                      ? ele.title
                        ? getStyleForCompany(ele.company.ranking).background
                        : getStyleForUniversity(
                            ele.university.ranking,
                            ele.postGrad
                          ).background
                      : undefined,
                  }}
                />
                {ele.overlappingExperience && (
                  <div className="flex gap-3">
                    {ele.overlappingExperience.map(
                      (wrkExp: TimelineCandidateExperience, i: number) => (
                        <div className="mt-2">
                          <div
                            style={{
                              background: getStyleForCompany(
                                wrkExp.company.ranking
                              ).background,
                              width: `${
                                ele.blockWidth *
                                  ((wrkExp.totalMonths / 100) * 1.5) +
                                50
                              }px`,
                            }}
                            className="h-2 rounded-full"
                          />
                          <h4
                            className="mt-3 mx-auto w-10 h-10 font-semibold text-white rounded-full flex justify-center items-center"
                            style={{
                              background: getStyleForCompany(
                                wrkExp.company.ranking
                              ).background,
                            }}
                          >
                            {wrkExp.totalMonths}
                          </h4>
                          <h5
                            className="font-medium text-xs truncate"
                            title={wrkExp.company.name}
                          >
                            {wrkExp.company.name}
                          </h5>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            );
          })}
          {i < timelineArr.length - 1 && (
            <div
              className="w-5 relative self-end"
              style={{
                minWidth: "20px",
                top: timelineArr[i].some(
                  (item: any) => item?.overlappingExperience?.length > 0
                )
                  ? "-101px"
                  : "-8px",
              }}
            >
              <div
                style={{
                  borderWidth: "6px",
                  borderLeft: "none",
                  rotate: i % 2 !== 0 ? "180deg" : "",
                  right: i % 2 !== 0 ? 0 : "",
                  height: timelineArr[i].some(
                    (item: any) => item?.overlappingExperience?.length > 0
                  )
                    ? "238px"
                    : "146px",
                }}
                className="absolute w-10 border-l-0 border-4 border-gray-600 rounded-r-full"
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
